import React, { useState } from 'react';
import { Button, MenuItem, FormControl, Select, InputLabel } from '@mui/material';
import DoubleArrowTwoToneIcon from '@mui/icons-material/DoubleArrowTwoTone';
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
    palette: {
        primary: {
            main: "#274690", // custom primary color
        },
        secondary: {
            main: "#1B7C88", // custom secondary color
        },
    },
});

function StateSelection({ setStep, setState }) {
    const [selectedState, setSelectedState] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        setState(selectedState);
        setStep('state-main');
    };

    const states = {
        AL: 'https://driverlicenseprep.com/Assets/signs/ALMap.png',
        NJ: 'https://driverlicenseprep.com/Assets/signs/NJMap.png',
    };

    const stateNames = {
        Alabama: 'Alabama',
        NewJersey: 'New Jersey',
        // Add other states as needed
    };

    return (
        <div className="container mx-auto p-4 lg:w-1/2 mb-4">
            <h2 className="text-2xl font-bold mb-4">States</h2>
            <form onSubmit={handleSubmit}>
                <FormControl fullWidth>
                    <InputLabel id="state-select-label"><em>Select a state</em></InputLabel>
                    <Select
                        value={selectedState}
                        required
                        onChange={(e) => setSelectedState(e.target.value)}
                        displayEmpty
                        labelId="state-select-label"
                        label="Select a state"
                    >

                        {/* <MenuItem value="Alabama">
                            <img
                                src={states.AL}
                                alt=''
                                style={{ width: '20px', marginRight: '8px' }}
                            />
                            {stateNames["Alabama"]}
                        </MenuItem> */}

                        <MenuItem value="NewJersey">
                            <img
                                src={states.NJ}
                                alt=''
                                style={{ width: '20px', marginRight: '8px' }}
                            />
                            {stateNames["NewJersey"]}
                        </MenuItem>
                        {/* Add other states here */}
                    </Select>
                </FormControl>
                <div className='mt-5'>
                    <ThemeProvider theme={theme}>
                        <Button type="submit" color='primary' endIcon={<DoubleArrowTwoToneIcon />} fullWidth className="" variant='contained'>Next</Button >
                    </ThemeProvider>
                </div>
            </form>
        </div>
    );
}

export default StateSelection;
