import React from 'react';
import './App.css';
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { createBrowserHistory } from "history";
import Footer from './components/Footer';
import Home from './Home';
import DriversLicensePractice from './DriversLicensePractice';
import Resources from './Resources';
import Navigation from './components/Navigation';
import Tips from './components/Tips';
import Contact from './Contact'

const history = createBrowserHistory();

function App() {

  return (
    <div className="bg-[#FFFFFF] text-[#191716]">
      <BrowserRouter history={history}>

        <Navigation />

        <Switch>
          <div className=''>
            <Route exact="true" path="/" component={Home} />
            <Route exact="true" path="/Resources" component={Resources} />
            <Route exact="true" path="/Tips" component={Tips} />
            <Route exact="true" path="/DriversLicensePractice" component={DriversLicensePractice} />
            <Route exact="true" path="/Contact" component={Contact} />

          </div>
        </Switch>

      </BrowserRouter>

      {/* <div><States /></div> */}
      <Footer />
    </div>
  );
}

export default App;
