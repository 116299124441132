import React, { useState } from 'react';
import StateSelection from './States/StateSelection';
import LanguageSelection from './States/LanguageSelection';
import Test from './States/Test';

import AlabamaMainPage from './States/Alabama/AlabamaMainPage';
import NewJerseyMainPage from './States/NewJersey/NewJerseyMainPage';
import All from './components/All';
import States from './components/States';

const DriversLicensePractice = () => {

    const [step, setStep] = useState('language');
    const [state, setState] = useState('');
    const [language, setLanguage] = useState('');

    const renderStateMainPage = () => {
        switch (state) {
            case 'Alabama':
                return <AlabamaMainPage setStep={setStep} language={language} />;
            case 'NewJersey':
                return <NewJerseyMainPage setStep={setStep} language={language} />;
            default:
                return null;
        }
    };

    return (
        <div>
            <div className='container'>
                {step === 'language' && <LanguageSelection setStep={setStep} setLanguage={setLanguage} />}
                {step === 'state' && <StateSelection setStep={setStep} setState={setState} />}
                {step === 'state-main' && renderStateMainPage()}
                {step === 'test' && <Test state={state} language={language} />}
                {step === 'all-questions' && <All state={state} language={language} />}
            </div>
        </div>
    )
}

export default DriversLicensePractice