import React from 'react';

function CompanyName({ state }) {
    const companyName = 'Driver License Prep';

    return (
        <span aria-label="" title='driver license prep, dmv prep test, driving test preparation'>
            {companyName} {state}
        </span>
    );
}

export default CompanyName;
