import React from 'react';
// import CountdownTimer from './components/CountdownTimer';
import { Helmet } from 'react-helmet';
// import DriversLicensePractice from './DriversLicensePractice';
import { Button } from '@mui/material';
import DoubleArrowTwoToneIcon from '@mui/icons-material/DoubleArrowTwoTone';
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
    palette: {
        primary: {
            main: "#274690", // custom primary color
        },
        secondary: {
            main: "#1B7C88", // custom secondary color
        },
    },
});


const Home = () => {
    return (
        <div className='container mx-auto'>
            <Helmet>
                <title>Driver License Prep | Practice Permit Tests & Study Guides</title>
                <meta name="description" content="Prepare for your driver's license test with our comprehensive practice permit tests and study guides in English, Arabic, Spanish, and French. Access free permit test prep, DMV prep tests, and more." />
                <meta name="keywords" content="driver license prep, free permit test prep, DMV prep test, driving test preparation, driver license study, drivers license test study guide, permit test preparation, driving test preparation lessons, Arabic, Spanish, French" />
                <meta name="author" content="Driver License Prep" />
                <link rel="canonical" href="https://www.driverlicenseprep.com/" />
                <meta property="og:title" content="Driver License Prep | Practice Permit Tests & Study Guides" />
                <meta property="og:description" content="Prepare for your driver's license test with our comprehensive practice permit tests and study guides in English, Arabic, Spanish, and French. Access free permit test prep, DMV prep tests, and more." />
                <meta property="og:url" content="https://www.driverlicenseprep.com/" />
                <meta property="og:type" content="website" />
                <meta property="og:image" content="https://www.driverlicenseprep.com/Assets/OGHome.jpg" />
            </Helmet>

            <div className='p-10'>

                <div className="">
                    <h1 className="text-4xl font-bold pb-5 CustomFont">Welcome to Driver License Prep</h1>
                    <div className="text-lg ">
                        Welcome to Driver License Prep, your ultimate resource for studying and practicing for your driver's license exam. We understand that preparing for your driver's license test can be a daunting task, but we're here to make the process as smooth and effective as possible. Our platform offers a comprehensive suite of tools and resources designed to help you pass your test with flying colors, no matter where you are in the United States.
                    </div>

                    {/* <h2 className="text-3xl font-semibold mt-8 mb-4">Why Choose Driver License Prep?</h2>
                    <p className="text-lg mb-4">
                        At Driver License Prep, we pride ourselves on providing a high-quality, user-friendly platform that caters to all your driver's license preparation needs. Here are just a few reasons why thousands of learners trust us with their test preparation:
                    </p> */}
                    {/* <ul className="list-disc list-inside mb-6">
                        <li className="text-lg mb-2">Extensive Question Bank: Our database contains hundreds of questions, covering all the essential topics you'll need to know for your test. Each question is carefully curated to reflect the latest standards and requirements for driver's license exams across the country.</li>
                        <li className="text-lg mb-2">Multiple Languages: We recognize that not everyone is comfortable studying in English. That's why we offer our practice tests and study materials in several languages, including Spanish, French, and Arabic. This ensures that you can prepare in the language you are most comfortable with.</li>
                        <li className="text-lg mb-2">State-Specific Tests: Each state has its own set of rules and regulations when it comes to driving. Our platform allows you to select your state and access state-specific practice tests that align with the actual exam you'll be taking. This tailored approach ensures you're studying the most relevant material.</li>
                        <li className="text-lg mb-2">Realistic Practice Tests: Our practice tests are designed to simulate the real exam experience. With timed tests and a variety of question formats, you'll be well-prepared for the types of questions you'll encounter on the actual test day.</li>
                        <li className="text-lg mb-2">Detailed Explanations: Understanding why an answer is correct is just as important as knowing the right answer. Our detailed explanations help you grasp the underlying concepts and improve your overall knowledge.</li>
                        <li className="text-lg mb-2">Progress Tracking: Keep track of your progress with our easy-to-use dashboard. Monitor your scores, identify areas for improvement, and watch your knowledge grow as you prepare for your test.</li>
                        <li className="text-lg mb-2">User-Friendly Interface: Our platform is designed with you in mind. With an intuitive layout and easy navigation, you'll find studying for your driver's license exam to be a hassle-free experience.</li>
                    </ul> */}

                    {/* <h2 className="text-3xl font-semibold mt-8 mb-4">How to Get Started</h2>
                    <p className="text-lg mb-4">
                        Getting started with Driver License Prep is simple and straightforward. Follow these steps to begin your journey toward earning your driver's license:
                    </p>
                    <ol className="list-decimal list-inside mb-6">
                        <li className="text-lg mb-2">Sign Up: Create an account on our platform to gain access to all our resources. Signing up is quick and easy.</li>
                        <li className="text-lg mb-2">Select Your State: Choose the state where you'll be taking your driver's license exam. This will ensure you get access to state-specific questions and study materials.</li>
                        <li className="text-lg mb-2">Choose Your Language: Select the language in which you'd like to study. We offer materials in English, Spanish, French, and Arabic to cater to a diverse range of learners.</li>
                        <li className="text-lg mb-2">Start Studying: Dive into our extensive question bank and begin studying. Use our practice tests to gauge your knowledge and identify areas for improvement.</li>
                        <li className="text-lg mb-2">Track Your Progress: Utilize our progress tracking tools to monitor your improvement. Set goals and strive to achieve them as you prepare for your test.</li>
                        <li className="text-lg mb-2">Take Practice Tests: Test your knowledge with our realistic practice tests. Get a feel for the actual exam experience and build your confidence.</li>
                    </ol> */}

                </div>


            </div>

            <div className='lg:w-1/2 mx-auto'>
                <div className='mx-10'>
                    <ThemeProvider theme={theme}>
                        <Button href='/DriversLicensePractice' color='primary' endIcon={<DoubleArrowTwoToneIcon />} fullWidth size='large' className="" variant='contained'>Start Test</Button >
                    </ThemeProvider>
                </div>
            </div>


            {/* <CountdownTimer /> */}
        </div>
    )
}

export default Home