import React, { useState } from 'react';
import { Button, MenuItem, FormControl, Select, InputLabel } from '@mui/material';
import DoubleArrowTwoToneIcon from '@mui/icons-material/DoubleArrowTwoTone';
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
    palette: {
        primary: {
            main: "#274690", // custom primary color
        },
        secondary: {
            main: "#1B7C88", // custom secondary color
        },
    },
});

// You can use URLs or paths to the flag images
const flags = {
    Eng: 'https://driverlicenseprep.com/Assets/Eng.png',
    Esp: 'https://driverlicenseprep.com/Assets/Esp.png',
    Fre: 'https://driverlicenseprep.com/Assets/Fre.png',
    Ara: 'https://driverlicenseprep.com/Assets/Ara.png'
};

const languages = {
    Eng: 'English',
    // Esp: 'Spanish',
    // Fre: 'French',
    Ara: 'Arabic'
};

function LanguageSelection({ setStep, setLanguage }) {
    const [selectedLanguage, setSelectedLanguage] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        setLanguage(selectedLanguage);
        setStep('state');
    };

    return (
        <div className="container mx-auto p-4">

            <form onSubmit={handleSubmit}>
                <div className="lg:w-1/2 mb-4 mx-auto">
                    <h2 className="text-2xl font-bold mb-4">Language</h2>
                    <FormControl fullWidth>
                        <InputLabel id="language-select-label"><em>Select a Language</em></InputLabel>
                        <Select
                            value={selectedLanguage}
                            onChange={(e) => setSelectedLanguage(e.target.value)}
                            displayEmpty
                            required
                            labelId="language-select-label"
                            label="Select a Language"

                        >

                            {Object.keys(languages).map((lang) => (
                                <MenuItem key={lang} value={lang}>
                                    <img
                                        src={flags[lang]}
                                        alt={languages[lang]}
                                        style={{ width: '20px', marginRight: '8px' }}
                                    />
                                    {languages[lang]}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>


                    <div className='mt-5'>
                        <ThemeProvider theme={theme}>
                            <Button type="submit" color="primary" size='large' endIcon={<DoubleArrowTwoToneIcon />} fullWidth className="" variant='contained'>Next</Button>
                        </ThemeProvider>
                    </div>
                </div>

            </form>
        </div>
    );
}

export default LanguageSelection;
