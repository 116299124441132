import React from 'react'

const Contact = () => {
    return (
        <div className='container mx-auto'>
            <div className='mx-10'>
                Contact Form

            </div>
        </div>
    )
}

export default Contact